<template>
    <div :style="`direction:${lang.dir}`">
        <v-form
            ref="crmForInv"
            lazy-validation
        >
        <b-sidebar backdrop width="80rem" id="whatsapp_crm" aria-labelledby="sidebar-no-header-title" no-header :style="`direction:`+lang.ldir" right>
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.view}}</span>
                    </div>
                    <div @click="hide" id="HideMeCRM" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="myBtn backBlack" :style="`direction:${lang.dir}`">
                    <div class="btnRow c_darkolivegreen">اعادة ارسال</div>
                    <div class="btnRow c_darkblue" >ارسال يدوي</div>
                    <div class="btnRow c_darkblue" >{{ lang.update }}</div>
                    <div class="btnRow c_darkred" >ايقاف الحملة</div>
                    <div class="btnRow c_red" @click="deleteThis()">{{ lang.delete }}</div>
                </div>
              
                <div class="myBtn" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">بيانات الحملة</h6>
                        
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">العنوان</th>
                                    <th class="text-center" style="min-width:100px;">تاريخ الاضافة</th>
                                    <th class="text-center" style="min-width:100px;">تاريخ البدء</th>
                                    <th class="text-center" style="min-width:100px;">وقت البدء</th>
                                </tr>
                                <tr>
                                    <td class="text-center">حملة الواتس</td>
                                    <td class="text-center" style="min-width:100px;">2023-10-05</td>
                                    <td class="text-center" style="min-width:100px;">2023-10-08</td>
                                    <td class="text-center" style="min-width:100px;">05:30 AM</td>
                                </tr>
                            </thead>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">احصائيات</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center">عدد العملاء</th>
                                    <th class="text-center">تمت القراءة</th>
                                    <th class="text-center">تمت الاستجابة</th>
                                    <th class="text-center">عدد المشترين</th>
                                    <th class="text-center">اكثر المدن شراء</th>
                                    <th class="text-center">اكثر المدن متابعة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center" style="height: 30px !important;">2000</td>
                                    <td class="text-center" style="height: 30px !important;">1500</td>
                                    <td class="text-center" style="height: 30px !important;">1000</td>
                                    <td class="text-center" style="height: 30px !important;">150</td>
                                    <td class="text-center" style="height: 30px !important;">الرياض</td>
                                    <td class="text-center" style="height: 30px !important;">الدمام</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">عملاء قامو بالرد</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">اسم العميل</th>
                                    <th class="text-center" style="min-width:50px;">رقم الاتصال</th>
                                    <th class="text-center" style="min-width:50px;">تاريخ الاستجابة</th>
                                    <th class="text-center" style="min-width:50px;">أخر رسالة</th>
                                    <th class="text-center" style="min-width:50px;">المدينة</th>
                                    <th class="text-center" style="min-width:50px;">الصناعية</th>
                                    <th class="text-center" style="min-width:50px;">ارسال رد</th>
                                    <!-- <th class="text-center" style="min-width:50px;">{{ lang.action }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">عملاء قامو بالشراء</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">اسم العميل</th>
                                    <th class="text-center" style="min-width:50px;">رقم الاتصال</th>
                                    <th class="text-center" style="min-width:50px;">تاريخ الشراء</th>
                                    <th class="text-center" style="min-width:50px;">رقم الفاتورة</th>
                                    <th class="text-center" style="min-width:50px;">تاريخ الفاتورة</th>
                                    <th class="text-center" style="min-width:50px;">المدينة</th>
                                    <th class="text-center" style="min-width:50px;">الصناعية</th>
                                    <th class="text-center" style="min-width:50px;">ارسال الفاتورة</th>
                                </tr>
                            </thead>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.quotations }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">اسم العميل</th>
                                    <th class="text-center" style="min-width:50px;">رقم الاتصال</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.quotation_no }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.quot_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center" style="min-width:50px;">{{ lang.action }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="font-weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.notes }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center" style="min-width:50px;">اسم العميل</th>
                                    <th class="text-center" style="min-width:50px;">رقم الاتصال</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.notes }}</th>
                                    <th class="text-center" style="min-width:50px;">{{ lang.added_by }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </v-simple-table>
                    </div>
                </div>
            </template>
        </b-sidebar>
        </v-form>
        <UpdateCustomer ref="updatecustomer" />
        <addClientNote ref="clientNotes" />
        <addInvoice ref="addinvoices" />
        <AddQuotation ref="AddQuotation" />
        <addNewCard ref="createnew" />
        <addClientTask ref="add_addClientTask" />
        <addClientWarranty ref="add_addClientWarranty" />
        <addCalendar ref="addCalendar" />
        <addHomeSupport ref="addHomeSupport" />
        <addContract ref="addContract" />
        <CarContractView ref="CarContractView" />
        <UpdateSamTask ref="UpdateSamTask" />
        <UpdateProcedures ref="UpdateProcedures" />
        <PublishMe ref="PublishMe" />
    </div>
</template>

<script>
import axios from 'axios'
import addClientNote from '@/components/addClientNotes.vue'
import addInvoice from '@/components/addInvoice.vue'
import AddQuotation from '@/components/addQuotation.vue'
import addNewCard from "@/components/addNewCard.vue"
import UpdateCustomer from '@/components/update-customer.vue'
import addClientTask from '@/components/addClientTask.vue'
import addClientWarranty from '@/components/addClientWarranty.vue'
import addCalendar from '@/components/addCalendar.vue'
import addHomeSupport from '@/components/addHomeSupport.vue'
import addContract from '@/components/addContract.vue'
import {SnotifyPosition} from 'vue-snotify';
import CarContractView from '@/components/crm/CarContractView.vue'
import UpdateSamTask from '@/components/UpdateSamTask.vue';
import UpdateProcedures from '@/components/UpdateProcedures.vue';
import PublishMe from '@/components/publish_me.vue';
export default {
    components: {PublishMe,UpdateProcedures,UpdateSamTask,CarContractView,addHomeSupport,addClientNote,addInvoice,AddQuotation,addNewCard,UpdateCustomer,addClientTask,addClientWarranty,addCalendar,addContract},
    data() {
        return {
            contracts: [],
            customerid: 0,
            tasks: [],
            fineReq: [
            ],
            notes: [],
            quotes:[],
            cards:[],
            invoices: [],
            cars:[],
            payments: [],
            statistic: {
                cars: 0,
                invoices: 0,
                cards: 0,
                quotations: 0,
            },
            finance: {
                invoices: 0,
                cards: 0,
                paid: 0,
                remain: 0
            },
            customer: {
                executed_date: '',
                _priority: {
                    name_ar: '-',
                    name_en: '-',
                    style: ''
                },
                _procedure: {
                    name_ar: '-',
                    name_en: '-',
                    style: ''
                }
            },
            warrantly: [],
            cusStatus: [],
            calendar: null
            // contact_agent: 1,
            // procedure: 1,
            // client_priority: 1,
            // exeute_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        conheaders: function() {
            let t = [
                {
                    text: this.lang.contract_id,
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.mobile,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.customer_name,
                    align: 'center',
                    filterable: false,
                    value: 'customer_name',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.last_maint_date,
                    align: 'center',
                    filterable: false,
                    value: 'project_type',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.last_maint_by,
                    align: 'center',
                    filterable: false,
                    value: 'last_maint_by',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.cars_number,
                    align: 'center',
                    filterable: false,
                    value: 'city',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    filterable: false,
                    value: 'total',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.vat,
                    align: 'center',
                    filterable: false,
                    value: 'vat',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.ftotal,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    value: 'agent',
                    sortable: false,
                    class: 'pa-0'
                },

            ];
            return t;
        },
        totlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.invoices.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.invoices[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.invoices[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.invoices[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.invoices[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.invoices[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        ctotlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.cards.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.cards[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.cards[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.cards[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.cards[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.cards[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        _custoemr_type: function(){
            let t = this.lang.individual_client
            if(this.customer.customer_type == 2){
                t = this.lang.company_clinet
            }
            if(this.customer.customer_type == 3){
                t = this.lang.government_clinet
            }
            return t;
        },
        bgColor: function(){
            let t = 'background: #335801 !important;color:#fff !important'
            if(this.customer.customer_type == 2){
                t = 'background: darkblue !important;color:#fff !important'
            }
            if(this.customer.customer_type == 3){
                t = 'background: #404258 !important;color:#fff !important'
            }
            return t;
        },

    },
    methods:{
        publish_me(){
            this.$refs.PublishMe.customerid = this.customer.customerid
        },
        aupProcedures(){
            const exDate = (new Date(this.customer.exeute_date)).toISOString().substr(0, 10);
            // // console.log(exDate);
            // console.log(this.customer.exeute_date);
            this.$refs.UpdateProcedures.crm_id = this.customer.customerid
            this.$refs.UpdateProcedures.procedure = this.customer.procedure
            this.$refs.UpdateProcedures.client_priority = this.customer.client_priority
            this.$refs.UpdateProcedures.contact_agent = this.customer.contact_agent
            this.$refs.UpdateProcedures.exeute_date = exDate
            this.$refs.UpdateProcedures.notes = ""
            // this.$refs.UpdateProcedures.consoleMe()
        },
        editTask(item) {
            this.$refs.UpdateSamTask.crm_id = item.id;
            this.$refs.UpdateSamTask.title = item.title;
            this.$refs.UpdateSamTask.description = item.description;
            this.$refs.UpdateSamTask.task_response = item.userid;
            this.$refs.UpdateSamTask.status = item.status;
            this.$refs.UpdateSamTask.notes = '';
            this.$refs.UpdateSamTask.getTaskDet();
            this.$refs.UpdateSamTask.show()
                
        },
        openView(id){
            this.$refs.CarContractView.getContracts (id);
        },
        getContracts(){
            const post = new FormData(); 
            post.append("type","getCarContracts");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customerid]",this.customerid)
            
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.contracts = response.data.results.data;
                }
            })

        },
        addSupport(){
            this.$refs.addHomeSupport.mobile = this.customer.mobile;
            this.$refs.addHomeSupport.getCustoemrs(2)
        },
        deleteThis(){
            if(this.customerid == 0) return false;
            if(this.statistic.invoices != 0 || this.statistic.cards != 0) return false;
            const post = new FormData();
            post.append("type","deleteCustomer")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customerid]",this.customerid)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    if(response.data.error.number != 200){
                        ///
                    }else{
                        this.$parent.getClients();
                        document.getElementById('HideMeCRM').click();
                    }

                    let message = this.lang[response.data.error.message];
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                }
            })
        },
        aupdate(){
            this.$refs.updatecustomer.customers = {
                id: this.customer.id,
                customerid: this.customer.customerid,
                mobile: this.customer.mobile,
                mobile1: typeof this.customer !== 'undefined' ? this.customer.mobile2 : '',
                full_name: this.customer.full_name,
                company_name: this.customer.company_name,
                company_vatid: this.customer.company_vatid,
                building_no: this.customer.building_no,
                street_name: this.customer.street_name,
                branch_number: this.customer.branch_number,
                district: this.customer.district,
                zipcode: this.customer.zipcode,
                city:this.customer.city,
                address:this.customer.address,
                customer_type: this.customer.customer_type,
                entity_name: this.customer.entity_name,
                branch_name: this.customer.branch_name,
                exeute_date: this.customer.exeute_date,
                contact_agent: this.customer.contact_agent,
                procedure: this.customer.procedure,
                client_priority: this.customer.client_priority,
                // crt_number: ''
            }
            this.$refs.updatecustomer.changeCusType(parseInt(this.customer.customer_type) - 1)
        },
        resetAllValues(){
            this.$refs.createnew.resetValues();
            this.$refs.createnew.getInvSettings();
            this.$refs.createnew.$refs.firstCar.image = '';
            this.$refs.createnew.customer.customerid = this.customerid;
            this.$refs.createnew.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.createnew.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.createnew.changeCusType(2);
            }
            else{
                this.$refs.createnew.changeCusType(0);
            }
            this.$refs.createnew.getName();
        },
        addQuot(){
            this.$refs.AddQuotation.reset();
            this.$refs.AddQuotation.customer.mobile = this.customer.mobile;
            this.$refs.AddQuotation.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.AddQuotation.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.AddQuotation.changeCusType(2);
            }
            else{
                this.$refs.AddQuotation.changeCusType(0);
            }
            this.$refs.AddQuotation.getName();
        },
        InvOP(){
            // console.log(this.customer);

            this.$refs.addinvoices.reset();
            this.$refs.addinvoices.customer.id = this.customerid;
            this.$refs.addinvoices.customer.mobile = this.customer.mobile;
            this.$refs.addinvoices.customer.customerid = this.customerid;
            this.$refs.addinvoices.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.addinvoices.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.addinvoices.changeCusType(2);
            }
            else{
                this.$refs.addinvoices.changeCusType(0);
            }
            this.$refs.addinvoices.getAllName();
        },
        addCalendar(){

            this.$refs.addCalendar.task_description = "";
            this.$refs.addCalendar.customerid = this.customerid;
        },
        openContract(){
            this.$refs.addContract.resetAll ();
            this.$refs.addContract.mobile = this.customer.mobile
            this.$refs.addContract.getCustomer ();
        },
        addTask(){
            this.$refs.add_addClientTask.task_description = "";
            this.$refs.add_addClientWarranty.task_description = "";
            this.$refs.add_addClientTask.customerid = this.customerid;
            this.$refs.add_addClientWarranty.customerid = this.customerid;
        },
        addNote(){

            this.$refs.clientNotes.notes = '';
            this.$refs.clientNotes.customerid = this.customerid;
        },
        getCurrentCards(){
            this.getCustomerInfo()
        },
        getClients(){
            this.getCustomerInfo()
        },
        async getCustomerInfo()
        {
            if(this.cusotmerid == 0){
                return false;
            }
            let post = new FormData();
            post.append('type' , 'getCustomerInfo');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('auth',auth);
            post.append('data[customerid]',this.customerid)
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            );
            if(response && response.data){
                this.getContracts()
                const cus = response.data.results.data.customer;
                const inv = response.data.results.data.invoices;
                const receipt = response.data.results.data.receipt;
                const cards = response.data.results.data.cards;
                const cars = response.data.results.data.cars;
                const quotes = response.data.results.data.quotes;
                const notes = response.data.results.data.notes;
                const tasks = response.data.results.data.tasks;
                const warrantly = response.data.results.data.warrantly;
                const calendar = response.data.results.data.calendar;
                this.customer ={
                    id: cus.id,
                    customerid: cus.customerid,
                    mobile: cus.mobile,
                    mobile2: cus.mobile2,
                    customer_type: cus.customer_type,
                    full_name: cus.full_name,
                    company_name: cus.company_name,
                    crt_number: cus.crt_number,
                    vatid: cus.company_vatid,
                    address: cus.address,
                    entity_name: cus.entity_name,
                    branch_name: cus.branch_name,
                    exeute_date: cus.exeute_date,
                    contact_agent: cus.contact_agent,
                    procedure: cus.procedure,
                    client_priority: cus.client_priority,
                    _procedure: cus._procedure,
                    _proirity: cus._proirity,
                    agent: cus.agent,
                    executed_date: cus.executed_date,
                }
                this.invoices = inv;
                this.payments = receipt;
                this.cards = cards;
                this.cars = cars;
                this.quotes = quotes;
                this.notes = notes;
                this.tasks = tasks;
                this.warrantly = warrantly;
                this.calendar = calendar;
                this.statistic.invoices = inv.length;
                this.statistic.cards = cards.length;
                this.statistic.cars = cars.length;
                this.statistic.quotations = quotes.length;
                this.finance.invoices = 0;
                this.finance.paid = 0;
                for(let i=0;i<inv.length;i++){
                    this.finance.invoices = parseFloat(this.finance.invoices) + parseFloat(inv[i].ftotal);
                    this.finance.paid = parseFloat(this.finance.paid) + parseFloat(inv[i].paid);
                }
                this.finance.cards = 0;
                for(let i=0;i<cards.length;i++){
                    this.finance.cards = parseFloat(this.finance.cards) + parseFloat(cards[i].remain);
                }
                this.finance.remain = parseFloat(this.finance.invoices) - parseFloat(this.finance.paid)
                this.finance.remain = parseFloat(this.finance.remain) + parseFloat(this.finance.cards)
            }
        },
        Cards(id){
            this.getIt(id);
        },
        Invoices(id){
            this.getIt(id);
        },
        Payments(id){
            this.getIt(id);
        },
        Quots(id){
            this.getIt(id);
        },
        getIt(id){
            const btns = [
                'c_cards', 'c_invoices','c_payments','c_quotations'
            ];
            for(let i=0;i< btns.length;i++){
                if(btns[i] == id){
                    document.getElementById(btns[i]).classList.remove('c_lightgray');
                    document.getElementById(btns[i]).classList.add('c_black');
                }else{
                    document.getElementById(btns[i]).classList.remove('c_black');
                    document.getElementById(btns[i]).classList.add('c_lightgray');
                }
            }
            

        },
        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.cusStatus.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
    },
    created(){
        // this.getStatus('customerStatus');
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>